/**
  The comments exist in an iframe with Coral Talk.
  ** Unless scoped, this styling will also apply to the Coral Talk admin pages. **
 */

@import './vars';

@font-face {
  font-family: "DINOT";
  font-weight: 400;
  src: url('https://storage.googleapis.com/zh-public-assets/fonts/DINOT-CondMedium.otf') format('opentype');
}

@font-face {
  font-family: "DINOT";
  font-weight: 700;
  src: url('https://storage.googleapis.com/zh-public-assets/fonts/DINOT-CondBold.otf') format('opentype');
}

.talk-load-all-comments {
  border: 1px solid #ccc;
  padding: 20px 15px;
  color: #919191;
  text-align: center;
  font-size: 14px;
  margin-top: 25px;
  width: 60%;
  margin-left: 20%;
  &:hover,
  &:active {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    cursor: pointer;
  }
}

.talk-ban-user-dialog {
  font-family: $zh-font;
}

// 'global' styles for embed/editors
.cke_editable, .talk-embed-stream {
  * {
    box-sizing: border-box;
  }
  font-family: $zh-font;
  p {
    margin: 0.25em 0 0.75em;
    line-height: 1.5;
  }
  em {
    font-style: italic;
  }
  strong, b {
    font-weight: bold;
  }
  a {
    color: $link-color;
    &:hover {
      color: lighten($link-color, 10);
    }
  }
  blockquote {
    position: relative;
    quotes: initial;
    margin: 0.25em 5px 0.75em;
    padding: 0.2em 1.5em;
    clear: both;
    font-style: italic;

    &:before {
      content: "";
      position: absolute;
      border-left: 5px solid #ededed;
      top: 0;
      bottom: 0;
      left: 0;
    }

    @media(min-width: $tablet-portrait) {
      margin-left: 4em;
      margin-right: 4em;
    }
  }
}

.cke_editable,
.talk-stream-comment {
  font-size: $base-font-size;
}

.talk-stream-comment {
  display: flex;
}

// Stop zoom on mobile devices by enlarging text in CKEditor
@media only screen and (max-width: 460px) {
  .cke_editable {
    font-size: 16px;
    select, textarea, input,
      // Specific dialog override, for links
    table.cke_dialog_contents input {
      font-size: 16px;
    }
  }
}


// Make CKEditor buttons match talk
$button-gray: #616161;
.cke_dialog_body a.cke_dialog_ui_button_ok {
  background: $button-gray;
  border-color: $button-gray;
  &:hover {
    color: white;
    background: lighten($button-gray, 10);
    border-color: lighten($button-gray, 10);
  }
}

// Thread spacing and borders on replies
@media(min-width: 480px) {
  @for $index from 1 through 5 {
    .embed-stream-page .talk-stream-comment-wrapper-level-#{$index} {
      margin-left: 38px;
      .talk-stream-comment {
        padding-left: 0;
      }
    }
  }

  .embed-stream-page .talk-stream-comment {
    border-left: none;
  }
}

.embed-stream-page .talk-embed-stream-moderation-link {
  color: $link-color;
  &:hover {
    color: lighten($link-color, 10);
  }
}

.embed-stream-page .talk-stream-comment-wrapper {
  border-top: 0;
}

.embed-stream-page .talk-stream-comment {
  display: flex;
  button:focus {
    outline: 0;
  }
}

.talk-stream-comment-container {
  flex: 1 0 auto;
  width: calc(100% - 50px);
}


.talk-stream-comment .talk-slot-comment-avatar {
  flex: 0 0 40px;
  max-width: unset;
  img {
    margin: 0 10px 0 0;
    width: 40px;
    height: auto;
  }
}

.talk-stream-comment .talk-slot-comment-author-name {
  margin-right: 6px;
  font-family: $accent-font;
  span {
    color: #4A4A4A;
  }
}

.talk-plugin-tag-label {
  background-color: #4C1066;
  color: white;
  display: inline-block;
  border-radius: 0;
  font-size: 12px;
  padding: 5px 6px;
  font-weight: bold;
  font-family: $accent-font;
  text-transform: uppercase;
}

.talk-stream-comment .talk-stream-comment-header {
  margin: 0 0 6px 0;
  align-items: center;
  line-height: 1;
  // Pop the info bar items below the username/postdate when it gets too squished
  flex-wrap: wrap;
  flex: 1 0 auto;
}

// Talk explicitly sets this to 12px :(
.talk-stream-comment .talk-stream-comment-published-date {
  font-size: $base-font-size;
}

@media(max-width: 625px) {
  .talk-stream-comment .talk-stream-comment-header-container {
    flex: 1 0 100%;
    margin-bottom: 10px;
    > * {
      display: inline-block;
    }
  }
}

$hide-buttons-breakpoint: 768px;

// Edit button also behaves like comment-info-bar, but does not have a selector :(
// Lucky for everyone, it's the only span child :)
.talk-stream-comment .talk-stream-comment-header > span {
  @media (min-width: $hide-buttons-breakpoint) {
    display: none;
  }
  @media (max-width: $hide-buttons-breakpoint - 1) {
    display: initial;
  }
}

.talk-stream-comment:hover .talk-stream-comment-header > span {
  display: initial;
}

.talk-stream-comment:hover .talk-slot-comment-info-bar {
  display: flex;
}

.talk-stream-comment .talk-slot-comment-info-bar {
  @media(min-width: $hide-buttons-breakpoint) {
    display: none;
  }
  @media(max-width: $hide-buttons-breakpoint - 1) {
    display: flex;
  }
  align-items: center;
  justify-content: flex-end;
  > div {
    padding: 0 10px;
    height: 14px;
    &:not(:first-child) {
      border-left: 1px solid #e4e4e4;
    }
  }

  .material-icons {
    color: #6b6b6b;
    // vertical-align: middle conflicts with flex align-items:center here
    vertical-align: initial;
    &:hover {
      color: darken(#6b6b6b, 20);
    }
  }

  .talk-plugin-flags-button-flagged .material-icons {
    color: $link-color;;
  }

  button {
    margin: 0;
  }
  // Hide labels
  button span {
    display: none;
  }
}

.talk-plugin-replies-reply-button {
  color: #6b6b6b;
  &:hover {
    color: darken(#6b6b6b, 20);
  }
}

.talk-stream-comment-edit-window {
  display: none;
}

// Pop moderation button to the end of the list
.talk-plugin-moderation-actions {
  order: 10;
}

// Popup adjustments
.talk-plugin-flags-popup {
  position: relative;
  > div {
    bottom: 25px;
    min-width: 200px;
    max-width: none;
    right: -10px;
    padding: 10px 12px;
    // Inline-style override D:
    top: auto !important;
    &:before,
    &:after {
      right: 7px;
    }
  }
  .talk-plugin-flags-popup-header {
    font-size: 14px;
  }
  .talk-plugin-flags-popup-radio-label,
  .talk-plugin-flags-popup-text {
    font-size: 12px;
  }
  .talk-plugin-flags-popup-text {
    margin-bottom: 4px;
  }
}

.talk-plugin-permalink-container {
  position: relative;
}

.talk-plugin-permalink-container .talk-plugin-permalink-popover {
  width: auto;
  min-width: 250px;
  right: -10px;
  left: auto;
  bottom: 20px;
  padding: 8px;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
  // Inline-style override D:
  top: auto !important;
  &:before,
  &:after {
    right: 17px;
  }
}

.talk-stream-comment-footer {
  margin-bottom: 10px;
}

.talk-plugin-vote-container,
.talk-plugin-vote-button {
  display: flex;
  align-items: center;
}

$vote-icon-size: 20px;
.talk-plugin-vote-container .talk-plugin-vote-button {
  color: #999;
  margin: 0 8px 0 0;
  &:focus {
    outline: none;
  }
  &:hover {
    color: darken(#999, 10);
  }
  &.talk-plugin-vote-voted:hover {
    color: $link-color;
  }
  &.talk-plugin-vote-upvoted {
    color: forestgreen;
  }
  &.talk-plugin-vote-downvoted {
    color: #EE3647;
  }
  i.talk-plugin-vote-icon {
    font-size: $vote-icon-size;
    line-height: 1;
  }
  .talk-plugin-vote-count {
    line-height: $vote-icon-size;
  }
}

// reply button container
.comment__action-button {
  display: flex;
  align-items: center;
}

.talk-plugin-replies-reply-button {
  margin: 0;
}

.talk-comment-collapsed {
  .talk-stream-comment-content,
  .talk-stream-comment-footer {
    display: none;
  }
}

.talk-stream-comment-content {
  margin: 5px 0;
}

.talk-plugin-comment-content-text > p:first-child {
  margin-top: 0;
}

.talk-plugin-viewing-options .talk-plugin-viewing-options-menu {
  min-width: 135px;
}

// Small adjustments to match zerohedge a bit more
.talk-stream-show-all-comments-button-container .talk-stream-show-all-comments-button,
.talk-load-more button
{
  background-color: $link-color;
  &:hover {
    color: white;
    background-color: lighten($link-color, 10);
  }
}

.talk-slot-author-menu-actions {
  flex-direction: column;
  margin-top: 0;
}

.comment-summary-reactions {
  display: none;
}

/** This is the edit button container **/
.talk-plugin-commentbox-button-container div[class^="Comment__buttonContainerLeft"] {
  display: none;
}

.talk-plugin-commentbox-button {
  font-family: $accent-font;
  text-transform: uppercase;
  min-width: 100px;
  font-size: 1.25em;
  &:not(:disabled) {
    font-weight: bold;
    background: $color-accent;
    color: $black;
  }
}

.talk-tab-button {
  font-family: $accent-font;
  font-size: 1.2em;
  text-transform: uppercase;
}

.talk-tab-count {
  margin-left: 0.25em;
}
.talk-tab-active.talk-tab-count {
  font-weight: bold;
}

// header
.talk-plugin-viewing-options button > span {
  font-family: $accent-font;
  font-size: 1em;
  text-transform: uppercase;
}
// arrow
.talk-plugin-viewing-options button > i {
  font-size: 25px;
}

.talk-plugin-viewing-options-menu {
  text-transform: uppercase;
  font-size: 0.85em;
}

// Category title
.talk-plugin-viewing-options-menu > div > div {
  font-family: $accent-font;
  font-size: 1.2em;
}

.talk-stream-auth-userbox {
  font-family: $accent-font;
  font-size: 1.15em;
  text-transform: uppercase;
  margin: 0.5em 0 0 0;
}

.talk-stream-premium-comments-cta {
  text-align: center;
  h1 {
    font-family: $accent-font;
    margin: 2em;
  }
  a {
    cursor: pointer;
    padding: 0.8em 1.1em;
    background-color: #F8E71C;
    border: none;
    font-family: $accent-font;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: $black;
  }
}

.talk-stream-premium-comments-info {
  background: $color-accent;
  color: $black;
  margin: 1em 0 2em;
  padding: 1em;
  h4 {
    margin: 0 0 0.25em 0;
    font-family: $accent-font;
    font-size: 1.5em;
    text-transform: uppercase;
  }
}
