// Colors
$black: #222;
$white: white;
$light-black: #4a4a4a;
$grey: #919191;
$dark-grey: #3d3d3d;
$menu-link-color: #646464;
$ad-grey: #f0f0f0;
$border-grey: #eee;
$header-border: #ccc;
$link_color: #1E439A;
$link_active_color: #4e0000;
$block_background: #f1f5ff;
$color-accent: #F7E847;

// Sidebar
$right-sidebar-width: 300px;

// Breakpooints
$mobile-portrait: 320px;
$mobile-landscape: 480px;
$tablet-portrait: 768px;
$tablet-landscape: 1025px;
$desktop: 1280px;
$mobile-only: max-width $tablet-portrait - 1;
$tablet-only: $mobile-landscape $tablet-landscape - 1;

// Fonts
$zh-font: "Helvetica Neue", "Helvetica", "Liberation Sans", "Arial", sans-serif;
$accent-font: "DINOT", Tahoma, Geneva, sans-serif;
$base-font-family: $zh-font; // The font family set on the html element.

// Font sizes
$base-font-size: 16px; // The font size set on the root html element.

// Header
$header-menu-color: lighten($light-black, 10);
$header-menu-hover-bk: lighten($header-border, 11);

// Custom mixin for Flexbox grid
$gutter: 30px;

@mixin span($columns) {
  flex: $columns $columns;
  // Flex basis 0 behaves incorrectly on IE
  @if $gutter*( $columns - 1) != 0 {
    flex-basis: $gutter*( $columns - 1);
  }
  @else {
    flex-basis: 100%;
  }
  margin: $gutter/2;
}
